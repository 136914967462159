import ReactDOM from 'react-dom';
import React, { useState, useEffect } from 'react';
import { fetchData } from '../helpers/base_data_fetcher';
import Modal from './Modal/index';

const InitialisePromoModal = () => {
  const getVerifiedMountEl = document.getElementById('getVerifiedModal');
  if (getVerifiedMountEl) {
    const { price } = getVerifiedMountEl.dataset;

    Promise.all([fetchData('site')]).then(([site]) => {
      ReactDOM.render(
        <GetVerifiedModal currency={site.currency_symbol} price={price} />,
        getVerifiedMountEl,
      );
    });
  }
};

const GetVerifiedModal = ({ currency, price }) => {
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    const triggerEl = document.getElementById('verifiedUserBtn');
    const srpResults = document.querySelectorAll('.listing-result');

    if (triggerEl) {
      triggerEl.addEventListener('click', open);
    }

    srpResults.forEach((result) => {
      const verifiedTag = result.querySelector('[data-verified]');

      if (verifiedTag) {
        verifiedTag.addEventListener('click', (event) => {
          event.preventDefault();
          event.stopPropagation();
          open();
        });
      }
    });
  }, []);

  const close = () => {
    setShowDialog(false);
  };

  const open = () => {
    setShowDialog(true);
  };

  return (
    <Modal
      close={close}
      showDialog={showDialog}
      id="get-verified-modal"
      modalLabelText="Get Verified with an ID check"
      wrapperClass="modal--verification-promo"
      initialFocus=".modal__close"
    >
      <ul className="verified-modal-list">
        <li>
          <i className="far fa-check-circle" aria-hidden="true"></i>
          Increase trust with people
        </li>
        <li>
          <i className="far fa-check-circle" aria-hidden="true"></i>
          Stand out and show you&apos;re committed
        </li>
        <li>
          <i className="far fa-check-circle"></i>
          Only takes a few minutes
        </li>
        <li>
          <i className="far fa-check-circle"></i>
          All for only {currency}
          {price}.
          <a
            className="verified-modal-list__link"
            href="/content/myaccount/verify/?M_context=407"
          >
            Find out more
          </a>
        </li>
      </ul>
    </Modal>
  );
};

export default InitialisePromoModal;
